import React from "react"

const Search = () => {
  
  const cx = '31aca023a28677f6b'; //数字の羅列:アルファベットの羅列
  let gcse = document.createElement('script');
  gcse.type = 'text/javascript';
  gcse.async = true;
  gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(gcse, s);
  return (
    <div>
      <div className="gcse-search"></div>
    </div>
  )
}

export default Search